header {
  color: white;
  background: #37387a;
  background-image: url("../../images/space.jpg");
  min-height: 50vh;
  padding-top: 20vh;
  text-align: center;
  /* position: relative; */
  z-index: 1;
  overflow: hidden;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

header h1 {
  font-size: 3rem;
  margin: 0 0 1rem;
}

header h2 {
  font-weight: 300;
  font-size: 1.5rem;
  margin: 0 0 1rem;
}

header a {
  color: #ffff;
}
